var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('breadcrumbs',{staticClass:"breadcrumb-center",attrs:{"with-homepage":true,"routes":[],"active-route":"Career"}}),_vm._v(" "),_c('div',{staticClass:"row mb50"},[_c('div',{staticClass:"col-xs-12 align-center"},[_c('h1',{staticClass:"fs-big weight-500"},[_vm._v("\n        "+_vm._s(_vm.$t('Dołącz do E-Garderobe'))+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-offset-2 col-md-4 col-lg-offset-3 col-lg-3 align-right brdr-right-1 brdr-cl-secondary"},[_c('div',{staticClass:"contact-info pr20 start-xs end-sm"},[_c('p',{staticClass:"h5"},[_vm._v("\n          "+_vm._s(_vm.$t('Pragniemy, aby marka E-Garderobe nieustannie się rozwijała. W związku z tym poszukujemy ambitnych i kreatywnych osób, które dołączą do naszego zespołu.'))+"\n        ")]),_vm._v(" "),_c('p',{staticClass:"uppercase weight-600 h5"},[_vm._v("\n          "+_vm._s(_vm.$t('Jeśli kochasz modę tak jak my wypełnij formularz i załącz swoje CV'))+"\n        ")])])]),_vm._v(" "),_c('div',{staticClass:"col-xs-12 col-sm-6 col-md-5 col-lg-4"},[_c('form',{staticClass:"contact-form row pl20 mt15",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.sendForm($event)}}},[_c('base-input',{staticClass:"col-xs-6 mb20",attrs:{"type":"text","name":"first-name","autocomplete":"given-name","placeholder":_vm.$t('First name *'),"validations":[
            {
              condition: !_vm.$v.firstName.required && _vm.$v.firstName.$error,
              text: _vm.$t('Field is required.')
            },
            {
              condition: !_vm.$v.firstName.minLength,
              text: _vm.$t('Name must have at least 2 letters.')
            }
          ]},on:{"blur":function($event){return _vm.$v.firstName.$touch()}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_vm._v(" "),_c('base-input',{staticClass:"col-xs-6 mb20",attrs:{"type":"text","name":"last-name","autocomplete":"last-name","placeholder":_vm.$t('Last name *'),"validations":[
            {
              condition: !_vm.$v.lastName.required && _vm.$v.lastName.$error,
              text: _vm.$t('Field is required.')
            }
          ]},on:{"blur":function($event){return _vm.$v.lastName.$touch()}},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_vm._v(" "),_c('base-input',{staticClass:"col-xs-6 mb20",attrs:{"type":"text","name":"phone-number","autocomplete":"tel","placeholder":_vm.$t('Phone Number *'),"validations":[
            {
              condition: !_vm.$v.phoneNumber.required && _vm.$v.phoneNumber.$error,
              text: _vm.$t('Field is required.')
            }
          ]},on:{"blur":function($event){return _vm.$v.phoneNumber.$touch()}},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_vm._v(" "),_c('base-input',{staticClass:"col-xs-6 mb20",attrs:{"type":"email","name":"email","autocomplete":"email","placeholder":_vm.$t('E-mail address *'),"validations":[
            {
              condition: !_vm.$v.email.required && _vm.$v.email.$error,
              text: _vm.$t('Field is required.')
            }
          ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('base-textarea',{staticClass:"col-xs-12 mb20",attrs:{"type":"text","name":"about-info","placeholder":_vm.$t('Tell us a few words about yourself'),"validations":[
            {
              condition: !_vm.$v.aboutInfo.required && _vm.$v.aboutInfo.$error,
              text: _vm.$t('Field is required.')
            }
          ]},on:{"blur":function($event){return _vm.$v.aboutInfo.$touch()}},model:{value:(_vm.aboutInfo),callback:function ($$v) {_vm.aboutInfo=$$v},expression:"aboutInfo"}}),_vm._v(" "),_c('div',{staticClass:"col-xs-12 mb20 cv-upload"},[_c('label',{staticClass:"w-100 fs-small block",attrs:{"for":"fileInput"}},[_vm._v(_vm._s(_vm.$t('Attach CV'))+" *")]),_vm._v(" "),_c('input',{attrs:{"id":"fileInput","type":"file"},on:{"change":_vm.onFileImport}}),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.uploadError),expression:"uploadError"}],staticClass:"validation-message block cl-error h6"},[_vm._v("\n            "+_vm._s(_vm.$t('Upload pdf files only'))+"\n          ")])]),_vm._v(" "),_c('base-checkbox',{staticClass:"col-xs-12 terms-checkbox mb20 cl-black",attrs:{"id":"condition1","validations":[{
            condition: !_vm.$v.condition1.required && _vm.$v.condition1.$error,
            text: _vm.$t('Field is required.')
          }]},on:{"blur":function($event){return _vm.$v.condition1.$reset()},"change":function($event){return _vm.$v.condition1.$touch()}},model:{value:(_vm.condition1),callback:function ($$v) {_vm.condition1=$$v},expression:"condition1"}},[_vm._v("\n          "+_vm._s(_vm.$t('Pzeczytałem i rozumiem politykę prywatności oraz wyrażam zgodę na otrzymanie spersonalizowanych informacji handlowych od e-garderobe drogą mailową.'))+" *\n        ")]),_vm._v(" "),_c('base-checkbox',{staticClass:"col-xs-12 terms-checkbox mb20 cl-black",attrs:{"id":"condition2","validations":[{
            condition: !_vm.$v.condition2.required && _vm.$v.condition2.$error,
            text: _vm.$t('Field is required.')
          }]},on:{"blur":function($event){return _vm.$v.condition2.$reset()},"change":function($event){return _vm.$v.condition2.$touch()}},model:{value:(_vm.condition2),callback:function ($$v) {_vm.condition2=$$v},expression:"condition2"}},[_vm._v("\n          "+_vm._s(_vm.$t('Oświadczam, że wyrażam zgodę na przetwarzanie przez e-garderobe z siedzibą w Warszawie moich danych osobowych'))+" *\n        ")]),_vm._v(" "),_c('button-full',{staticClass:"mb20 w-100",attrs:{"disabled":_vm.$v.$invalid || _vm.uploadError,"type":"submit"}},[_vm._v("\n          "+_vm._s(_vm.$t('Send'))+"\n        ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }