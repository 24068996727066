<template>
  <div class="container">
    <breadcrumbs class="breadcrumb-center" :with-homepage="true" :routes="[]" active-route="Career" />
    <div class="row mb50">
      <div class="col-xs-12 align-center">
        <h1 class="fs-big weight-500">
          {{ $t('Dołącz do E-Garderobe') }}
        </h1>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-offset-2 col-md-4 col-lg-offset-3 col-lg-3 align-right brdr-right-1 brdr-cl-secondary">
        <div class="contact-info pr20 start-xs end-sm">
          <p class="h5">
            {{ $t('Pragniemy, aby marka E-Garderobe nieustannie się rozwijała. W związku z tym poszukujemy ambitnych i kreatywnych osób, które dołączą do naszego zespołu.') }}
          </p>
          <p class="uppercase weight-600 h5">
            {{ $t('Jeśli kochasz modę tak jak my wypełnij formularz i załącz swoje CV') }}
          </p>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6 col-md-5 col-lg-4">
        <form @submit.prevent="sendForm" novalidate class="contact-form row pl20 mt15">
          <base-input
            class="col-xs-6 mb20"
            type="text"
            name="first-name"
            autocomplete="given-name"
            v-model="firstName"
            @blur="$v.firstName.$touch()"
            :placeholder="$t('First name *')"
            :validations="[
              {
                condition: !$v.firstName.required && $v.firstName.$error,
                text: $t('Field is required.')
              },
              {
                condition: !$v.firstName.minLength,
                text: $t('Name must have at least 2 letters.')
              }
            ]"
          />
          <base-input
            class="col-xs-6 mb20"
            type="text"
            name="last-name"
            autocomplete="last-name"
            v-model="lastName"
            @blur="$v.lastName.$touch()"
            :placeholder="$t('Last name *')"
            :validations="[
              {
                condition: !$v.lastName.required && $v.lastName.$error,
                text: $t('Field is required.')
              }
            ]"
          />
          <base-input
            class="col-xs-6 mb20"
            type="text"
            name="phone-number"
            v-model="phoneNumber"
            @blur="$v.phoneNumber.$touch()"
            autocomplete="tel"
            :placeholder="$t('Phone Number *')"
            :validations="[
              {
                condition: !$v.phoneNumber.required && $v.phoneNumber.$error,
                text: $t('Field is required.')
              }
            ]"
          />
          <base-input
            class="col-xs-6 mb20"
            type="email"
            name="email"
            autocomplete="email"
            v-model="email"
            @blur="$v.email.$touch()"
            :placeholder="$t('E-mail address *')"
            :validations="[
              {
                condition: !$v.email.required && $v.email.$error,
                text: $t('Field is required.')
              }
            ]"
          />
          <base-textarea
            class="col-xs-12 mb20"
            type="text"
            name="about-info"
            v-model="aboutInfo"
            @blur="$v.aboutInfo.$touch()"
            :placeholder="$t('Tell us a few words about yourself')"
            :validations="[
              {
                condition: !$v.aboutInfo.required && $v.aboutInfo.$error,
                text: $t('Field is required.')
              }
            ]"
          />
          <div class="col-xs-12 mb20 cv-upload">
            <label class="w-100 fs-small block" for="fileInput">{{ $t('Attach CV') }} *</label>
            <input id="fileInput" type="file" @change="onFileImport">
            <span v-show="uploadError" class="validation-message block cl-error h6">
              {{ $t('Upload pdf files only') }}
            </span>
          </div>
          <base-checkbox
            class="col-xs-12 terms-checkbox mb20 cl-black"
            id="condition1"
            v-model="condition1"
            @blur="$v.condition1.$reset()"
            @change="$v.condition1.$touch()"
            :validations="[{
              condition: !$v.condition1.required && $v.condition1.$error,
              text: $t('Field is required.')
            }]"
          >
            {{ $t('Pzeczytałem i rozumiem politykę prywatności oraz wyrażam zgodę na otrzymanie spersonalizowanych informacji handlowych od e-garderobe drogą mailową.') }} *
          </base-checkbox>
          <base-checkbox
            class="col-xs-12 terms-checkbox mb20 cl-black"
            id="condition2"
            v-model="condition2"
            @blur="$v.condition2.$reset()"
            @change="$v.condition2.$touch()"
            :validations="[{
              condition: !$v.condition2.required && $v.condition2.$error,
              text: $t('Field is required.')
            }]"
          >
            {{ $t('Oświadczam, że wyrażam zgodę na przetwarzanie przez e-garderobe z siedzibą w Warszawie moich danych osobowych') }} *
          </base-checkbox>
          <button-full :disabled="$v.$invalid || uploadError" class="mb20 w-100" type="submit">
            {{ $t('Send') }}
          </button-full>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonFull from 'theme/components/theme/ButtonFull'
import { isServer } from '@vue-storefront/core/helpers'
import Breadcrumbs from '../components/core/Breadcrumbs'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox.vue'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput.vue'
import BaseTextarea from 'theme/components/core/blocks/Form/BaseTextarea.vue'
import { required, email, minLength, sameAs, alpha } from 'vuelidate/lib/validators'
import { MailerModule } from '@vue-storefront/core/modules/mailer'
import { registerModule } from '@vue-storefront/core/lib/modules'
import config from 'config'

export default {
  name: 'Career',
  data () {
    return {
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
      aboutInfo: '',
      condition1: false,
      condition2: false,
      uploadedFile: '',
      uploadError: false
    }
  },
  components: {
    ButtonFull,
    Breadcrumbs,
    BaseCheckbox,
    BaseInput,
    BaseTextarea
  },
  validations: {
    email: {
      required,
      email
    },
    firstName: {
      minLength: minLength(2),
      required
    },
    lastName: {
      required
    },
    phoneNumber: {
      required
    },
    aboutInfo: {
      required
    },
    condition1: {
      sameAs: sameAs(() => true)
    },
    condition2: {
      sameAs: sameAs(() => true)
    }
  },
  methods: {
    sendEmail (letter, success, failure) {
      this.$store.dispatch('mailer/sendEmail', letter)
        .then(res => {
          if (res.ok) {
            if (success) success(this.$t('Email has successfully been sent'))
          } else {
            return res.json()
          }
        })
        .then(errorResponse => {
          if (errorResponse) {
            const errorMessage = errorResponse.result
            if (failure) failure(this.$t(errorMessage))
          }
        })
        .catch(() => {
          if (failure) failure(this.$t('Could not send an email. Please try again later.'))
        })
    },
    async sendForm () {
      await this.sendEmail({
        senderName: 'E-Garderobe',
        sourceAddress: this.email,
        targetAddress: 'info@mail2.e-garderobe.com',
        subject: 'Formularz ze strony kariera',
        attachment: this.uploadedFile,
        emailText: `
        E-mail: ${this.email},
        Imię: ${this.firstName},
        Nazwisko: ${this.lastName},
        Numer telefonu: ${this.phoneNumber},
        O sobie: ${this.aboutInfo}`
      },
      this.onSuccess,
      this.onFailure
      )
    },
    onSuccess (message) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message,
        action1: { label: this.$t('OK') }
      })
      this.sendEmail(
        {
          senderName: 'E-Garderobe',
          sourceAddress: 'info@mail2.e-garderobe.com',
          targetAddress: this.email,
          subject: this.$t('Potwierdzenie wysłania formularza'),
          emailText: 'Dziekujemy za wypełnienie formularza na stronie e-garderobe.com!',
          confirmation: true
        }
      )
    },
    onFailure (message) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'error',
        message,
        action1: { label: this.$t('OK') }
      })
    },
    onFileImport (event) {
      let uploadedFile = event.target.files[0]
      if (uploadedFile && uploadedFile.type === 'application/pdf') {
        this.createBase64Image(uploadedFile)
        this.uploadError = false
      } else {
        this.uploadError = true
      }
    },
    createBase64Image (fileObject) {
      const reader = new FileReader()
      reader.onload = (e) => {
        this.uploadedFile = e.target.result
      }
      reader.readAsDataURL(fileObject)
      reader.readAsBinaryString(fileObject)
    }
  },
  beforeCreate () {
    registerModule(MailerModule)
  },
  beforeRouteEnter (to, from, next) {
    if (isServer) {
      next()
    } else {
      next(vm => {
        vm.$store.commit('ui/routeChanged', { to: to, from: from })
      })
    }
  },
  metaInfo () {
    return {
      htmlAttrs: { lang: config.storeViews[this.$store.state.storeView.storeCode].i18n.defaultLocale },
      meta: [
        {
          vmid: 'og:locale',
          property: 'og:locale',
          content: this.$store.state.storeView.storeCode ? config.storeViews[this.$store.state.storeView.storeCode].i18n.defaultLocale : ''
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-main: color(main);
.breadcrumb-center {
  justify-content: center;
  padding-top: 20px;
}
@media (max-width: 574px) {
  .contact-info, .contact-form {
    padding-left: 0;
    padding-right: 0;
  }
}
.cv-upload {
  #fileInput {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    padding: 5px;
    border: 1px solid;
    border-color: #D6D8DE;
    color: #707070;
    font-family: 'Poppins';
  }
}
/deep/ input::-webkit-file-upload-button {
  background: #c8c8c8;
  border: 0;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 0.8em;
  font-weight: 600;
  font-family: 'Poppins';
}
@media (max-width: 767px) {
  .fs-big {
      font-size: 28px;
      font-weight: 400;
  }
}
</style>
